
<nav
  ref="navRef"
  :class="{ collapsed: !expanded, 'navbar-beta': isBeta }"
  class="navbar navbar-vertical fixed-left navbar-expand-lg navbar-dark"
  @mouseover="onMouseOver"
  @mouseout="onMouseOut"
>
  <b-container fluid>
    <b-navbar-toggle target="sidebarCollapse" />

    <RouterLink v-if="expanded" class="navbar-brand" :to="{ name: 'home' }">
      {{ setting('ui_name') }}

      <h3 class="grey mb-0">
        {{ setting('ui_system_name') }}
      </h3>
    </RouterLink>
    <div v-else class="mt-5" />

    <b-navbar-nav v-if="isTablet">
      <b-nav-item>
        <LanguagePopover v-if="setting('ui_menu_language') === true" />
      </b-nav-item>
    </b-navbar-nav>

    <b-collapse
      id="sidebarCollapse"
      ref="sidebarCollapse"
      class="sidebar-collapse"
    >
      <b-navbar-nav>
        <b-nav-item
          v-for="item in rootNavItems.slice(0, 1)"
          :id="`popover-target-${item.title.toLowerCase()}`"
          :key="item.title.toLowerCase()"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
          :link-classes="{
            'd-flex': true,
          }"
          :class="{ minimized: !expanded }"
        >
          <Icon
            class="grey"
            :class="{ 'mr-3': expanded }"
            :icon="item.icon"
          />
          <span v-if="expanded">{{ item.title }}</span>
          <RouterLink
            v-if="expanded && item.counter && item.counter.value > 0"
            v-b-popover.hover.html.bottom="item.counter.popoverTitle"
            :to="item.counter.to"
            class="badge ml-auto"
            :class="item.counter.className"
          >
            {{ applyCompactNotation(item.counter.value) }}
          </RouterLink>
        </b-nav-item>
        <b-nav-item
          v-if="showInbox"
          :id="`popover-target-inbox`"
          :key="`inbox`"
          v-can-any="['inbox.personal.view', 'inbox.company.view']"
          :to="{ name: 'inbox.list' }"
          :active="isNavActive('inbox', $route.name)"
          :link-classes="{
            'd-flex': true,
          }"
          :class="{ minimized: !expanded }"
        >
          <Icon class="grey" :class="{ 'mr-3': expanded }" icon="inbox" />
          <span v-if="expanded">{{ __('menu.inbox') }}</span>
        </b-nav-item>
        <li
          id="popover-target-quotes"
          class="nav-item nowrap position-relative"
          :class="{ minimized: !expanded }"
          role="button"
          tabindex="0"
          style="overflow-anchor: none"
        >
          <RouterLink
            :to="{ name: 'quotes.list' }"
            class="nav-link"
            :class="{ active: quotesClassActiveState }"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon
                class="grey"
                :class="{ 'mr-3': expanded }"
                icon="file-alt"
              />
              <span v-if="expanded">{{ __('menu.quotes') }}</span>
            </div>
          </RouterLink>
          <div class="position-absolute quote-progress-link-div">
            <RouterLink
              v-if="expanded && quoteStateCount > 0"
              v-b-popover.hover.html.bottom="__('statuses.in-progress')"
              :to="{ name: 'quotes.list', params: { tab: 'in-progress' } }"
              class="badge badge-primary mt-n1"
            >
              {{ quoteStateCount }}
            </RouterLink>
            <Icon
              v-if="expanded"
              v-b-toggle="expanded ? 'quotes-collapse' : null"
              class="grey ml-2 cursor-pointer"
              :class="{ active: quotesClassActiveState }"
              icon="angle-down"
            />
          </div>
        </li>

        <b-collapse
          id="quotes-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in quotesNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item w-100">
                <div
                  class="d-flex align-items-center justify-content-between"
                >
                  <div>
                    <Icon class="grey mr-3" :icon="item.icon" size="13" />
                    {{ item.title }}
                  </div>
                  <b-badge
                    v-if="item.counter && item.counter.value"
                    class="ml-3"
                    :variant="item.counter.variant"
                  >
                    {{ applyCompactNotation(item.counter.value) }}
                  </b-badge>
                </div>
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>

        <b-nav-item
          v-for="item in rootNavItems.slice(1, 4)"
          :id="`popover-target-${item.title.toLowerCase()}`"
          :key="item.title.toLowerCase()"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
          :link-classes="{
            'd-flex': true,
          }"
          :class="{ minimized: !expanded }"
        >
          <Icon
            class="grey"
            :class="{ 'mr-3': expanded }"
            :icon="item.icon"
          />
          <span v-if="expanded">{{ item.title }}</span>
          <RouterLink
            v-if="expanded && item.counter && item.counter.value > 0"
            v-b-popover.hover.html.bottom="item.counter.popoverTitle"
            :to="item.counter.to"
            class="badge ml-auto"
            :class="item.counter.className"
          >
            {{ item.counter.value }}
          </RouterLink>
        </b-nav-item>

        <b-nav-item
          id="popover-target-finance"
          v-can-any="['invoices.view', 'payments.view', 'statements.view']"
          v-b-toggle="expanded ? 'finances-collapse' : null"
          class="nowrap"
          :link-classes="{
            'd-flex': true,
            'justify-content-between': expanded,
          }"
          :class="{ minimized: !expanded }"
          :active="financesClassActiveState"
        >
          <div class="d-flex justify-content-between align-items-center">
            <Icon class="grey" :class="{ 'mr-3': expanded }" icon="coins" />
            <span v-if="expanded">{{ __('menu.finances') }}</span>
          </div>

          <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
        </b-nav-item>

        <b-collapse
          id="finances-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in financesNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item">
                <Icon class="grey mr-3" :icon="item.icon" size="13" />
                {{ item.title }}
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>

        <b-nav-item
          id="popover-target-underwriting"
          v-can-any="[
            'schemas.view',
            'binders.view',
            'workflows.view',
            'datasets.view',
            'endorsement-libraries.view',
            'endorsement-groups.view',
            'products.view',
          ]"
          v-b-toggle="expanded ? 'underwriting-collapse' : null"
          class="nowrap"
          :link-classes="{
            'd-flex': true,
            'justify-content-between': expanded,
          }"
          :class="{ minimized: !expanded }"
          :active="underwritingClassActiveState"
        >
          <div class="d-flex justify-content-between align-items-center">
            <Icon
              class="grey"
              :class="{ 'mr-3': expanded }"
              icon="edit-alt"
            />
            <span v-if="expanded">{{ __('menu.underwriting') }}</span>
          </div>

          <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
        </b-nav-item>

        <b-collapse
          id="underwriting-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in underwritingNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item">
                <Icon class="grey mr-3" :icon="item.icon" size="13" />
                {{ item.title }}
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>

        <b-nav-item
          id="popover-target-admin"
          v-can-any="[
            'entities.view',
            'users.view',
            'roles.view',
            'permissions.view',
            'quotes.object-groups.view',
            'quotes.document-templates.view',
            'quotes.scheduled-tasks.view',
            'quotes.email-templates.view',
            'quotes.document-types.view',
            'quotes.translation-libraries.view',
            'quotes.counters.view',
          ]"
          v-b-toggle="expanded ? 'admin-collapse' : null"
          class="nowrap"
          :link-classes="{
            'd-flex': true,
            'justify-content-between': expanded,
          }"
          :class="{ minimized: !expanded }"
          :active="adminClassActiveState"
        >
          <div class="d-flex justify-content-between align-items-center">
            <Icon class="grey" :class="{ 'mr-3': expanded }" icon="setting" />
            <span v-if="expanded">{{ __('menu.admin') }}</span>
          </div>

          <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
        </b-nav-item>

        <b-collapse
          id="admin-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in adminNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item">
                <Icon class="grey mr-3" :icon="item.icon" size="13" />
                {{ item.title }}
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>

        <b-nav-item
          id="popover-target-data-exchange"
          v-can-any="['imports.view', 'exports.data']"
          v-b-toggle="expanded ? 'data-exchange-collapse' : null"
          class="nowrap"
          :link-classes="{
            'd-flex': true,
            'justify-content-between': expanded,
          }"
          :class="{ minimized: !expanded }"
          :active="dataExchangeClassActiveState"
        >
          <div class="d-flex justify-content-between align-items-center">
            <Icon
              class="grey"
              :class="{ 'mr-3': expanded }"
              icon="database"
            />
            <span v-if="expanded">{{ __('menu.data-exchange') }}</span>
          </div>

          <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
        </b-nav-item>

        <b-collapse
          id="data-exchange-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in dataExchangeNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item">
                <Icon class="grey mr-3" :icon="item.icon" size="13" />
                {{ item.title }}
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>

        <b-nav-item
          id="popover-target-reports-and-analytics"
          v-can-any="['reports.view', 'data-models.view']"
          v-b-toggle="expanded ? 'reports-and-analytics-collapse' : null"
          class="nowrap"
          :link-classes="{
            'd-flex': true,
            'justify-content-between': expanded,
          }"
          :class="{ minimized: !expanded }"
          :active="reportsAndAnalyticsClassActiveState"
        >
          <div class="d-flex justify-content-between align-items-center">
            <Icon
              class="grey"
              :class="{ 'mr-3': expanded }"
              icon="chart-bar"
            />
            <span v-if="expanded">{{ __('menu.reports-analytics') }} </span>
          </div>

          <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
        </b-nav-item>

        <b-collapse
          id="reports-and-analytics-collapse"
          accordion="sidebar-accordion"
          tag="li"
        >
          <ul v-if="expanded" class="p-0">
            <b-nav-item
              v-for="(item, index) in reportsAndAnalyticsNavItems"
              :key="index"
              v-can-any="item.permissions"
              :to="item.to"
              :active="item.active"
            >
              <div class="nav-sub-item">
                <Icon class="grey mr-3" :icon="item.icon" size="13" />
                {{ item.title }}
              </div>
            </b-nav-item>
          </ul>
        </b-collapse>
      </b-navbar-nav>
    </b-collapse>

    <b-popover
      v-for="item in rootNavItems"
      :key="item.title.toLowerCase()"
      :target="`popover-target-${item.title.toLowerCase()}`"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
          :link-classes="{
            'active-indicator-none': true,
          }"
        >
          <div>
            <Icon class="grey mr-2" size="13" :icon="item.icon" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-quotes"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="quotesClassActiveState" disabled>
          <span class="font-weight-bold">{{ __('menu.quotes') }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in quotesNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-finance"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="financesClassActiveState" disabled>
          <span class="font-weight-bold">{{ __('menu.finances') }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in financesNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-underwriting"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="underwritingClassActiveState" disabled>
          <span class="font-weight-bold">{{ __('menu.underwriting') }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in underwritingNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-admin"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="adminClassActiveState" disabled>
          <span class="font-weight-bold">{{ __('menu.admin') }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in adminNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-data-exchange"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="dataExchangeClassActiveState" disabled>
          <span class="font-weight-bold">{{ __('menu.data-exchange') }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in dataExchangeNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <b-popover
      v-if="!expanded"
      target="popover-target-reports-and-analytics"
      triggers="hover"
      placement="right"
      :custom-class="{
        'navigation-popover': true,
        'navbar-dark': true,
        'navbar-beta': isBeta,
      }"
    >
      <b-navbar-nav v-if="!expanded">
        <b-nav-item :active="reportsAndAnalyticsClassActiveState" disabled>
          <span class="font-weight-bold">{{
            __('menu.reports-analytics')
          }}</span>
        </b-nav-item>
        <b-nav-item
          v-for="(item, index) in reportsAndAnalyticsNavItems"
          :key="index"
          v-can-any="item.permissions"
          :to="item.to"
          :active="item.active"
        >
          <div>
            <Icon class="grey mr-3" :icon="item.icon" size="13" />
            {{ item.title }}
          </div>
        </b-nav-item>
      </b-navbar-nav>
    </b-popover>

    <div v-if="!isTablet" class="text-center grey d-none d-lg-block">
      <b-row no-gutters align-content="center" align-h="center">
        <b-col cols="12" class="mb-2">
          <LanguagePopover v-if="setting('ui_menu_language') === true" />
        </b-col>
        <b-col cols="auto" class="d-flex mb-3">
          <RouterLink to="/release-notes" class="nav-item-link">
            <small>v.{{ version }}</small>
          </RouterLink>
          <span class="nav-item-link-separator ml-2">|</span>
          <RouterLink
            v-can:videos.view
            to="/videos"
            class="nav-item-link ml-2"
            :active="isNavActive('videos.', $route.name)"
          >
            {{ tc('menu.help-video', 2) }}
          </RouterLink>
          <span class="nav-item-link-separator ml-2">|</span>
          <a
            class="nav-item-link ml-2"
            target="_blank"
            :href="getDocumentationHref()"
          >
            API
          </a>
        </b-col>
        <b-col v-if="isBeta" cols="12">
          <b-badge :v-if="isBeta" pill variant="success"> BETA </b-badge>
        </b-col>
        <b-col cols="12">
          <small>by Insly Ltd</small>
        </b-col>
      </b-row>
    </div>
  </b-container>
</nav>
